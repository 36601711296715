// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-deals {
  background-color: #ffae00;
  padding: 20px;
  max-width: 100%;
}

.all-deals h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.deal-item-2 {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 10px;
}

.deal-item-2 img {
  width: 220px;
  /* Adjust accordingly */
  height: 180px;
  margin-right: 50px;
}

.deal-content-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.deal-content-2 h4 {
  margin: 0;
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/css/AnotherSection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,SAAS;EACT,eAAe;AACjB","sourcesContent":[".all-deals {\n  background-color: #ffae00;\n  padding: 20px;\n  max-width: 100%;\n}\n\n.all-deals h3 {\n  font-size: 1.5rem;\n  margin-bottom: 20px;\n}\n\n.deal-item-2 {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n  padding: 10px;\n  background-color: #f8f8f8;\n  border-radius: 10px;\n}\n\n.deal-item-2 img {\n  width: 220px;\n  /* Adjust accordingly */\n  height: 180px;\n  margin-right: 50px;\n}\n\n.deal-content-2 {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.deal-content-2 h4 {\n  margin: 0;\n  font-size: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
