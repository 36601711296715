import './App.css';
import AnotherSection from './component/AnotherSection';
import Header from './component/Header';
import HeroSection from './component/HeroSection';
import TopDeals from './component/TopDeals';

function App() {
  return (
    <div>
      <Header />
      <main className="container1">
        <HeroSection />
        <TopDeals />
      </main>
      <AnotherSection />
    </div>
  );
}

export default App;
