// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #ffae00;
  /* Bright Yellow */
  font-family: 'Arial, sans-serif';
  margin: 0;
  padding: 0;
}

.container1 {
  display: flex;
  justify-content: space-between;
  /* Optional: Adjusts spacing between sections */
  align-items: flex-start;
  /* Optional: Aligns sections at the top */
}

.top-deals,
.hero-section {
  flex: 1 1;
  /* Adjusts how much space each section takes. */
  margin: 0 10px;
  /* Optional: Adds space between the sections */
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gCAAgC;EAChC,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,+CAA+C;EAC/C,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;;EAEE,SAAO;EACP,+CAA+C;EAC/C,cAAc;EACd,8CAA8C;AAChD","sourcesContent":["body {\n  background-color: #ffae00;\n  /* Bright Yellow */\n  font-family: 'Arial, sans-serif';\n  margin: 0;\n  padding: 0;\n}\n\n.container1 {\n  display: flex;\n  justify-content: space-between;\n  /* Optional: Adjusts spacing between sections */\n  align-items: flex-start;\n  /* Optional: Aligns sections at the top */\n}\n\n.top-deals,\n.hero-section {\n  flex: 1;\n  /* Adjusts how much space each section takes. */\n  margin: 0 10px;\n  /* Optional: Adds space between the sections */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
