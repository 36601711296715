import React, { useEffect, useState } from 'react';
import '../css/AnotherSection.css';

const AnotherSection = () => {
    const [deals, setDeals] = useState([]);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        fetch(`${apiUrl}/top50`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setDeals(data);
                console.log(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <section className="all-deals">
            <ul>
                {deals.map((deal, index) => (
                    <a href={deal.smartLink} target="_blank" rel="noreferrer"><li key={index} className="deal-item-2">
                        <img src={deal.imageUrl || require('../logos/stock.png')} alt={deal.title} />
                        <div className="deal-content-2">
                            <a href={deal.smartLink} target="_blank" rel="noreferrer"><h4>{deal.offerText}</h4></a>
                            <span>➔</span>
                        </div>
                    </li></a>
                ))}
            </ul>
        </section>
    );
};

export default AnotherSection;
