import React, { useEffect, useState } from 'react';
import '../css/HeroSection.css';

const HeroSection = () => {

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/stores`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setStores(data);
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);



  return (
    <section className="hero-section">
      <div className="welcome-message">
        <h2>Welcome to all the sales</h2>
        <h2>Up to 75% off</h2>
      </div>
      <div className="popular-stores">
        <h3>Our most popular stores</h3>
        <div className="stores-icons-1">
          {stores.slice(0, 5).map((store, index) => {
            const lowerCaseStoreName = store.toLowerCase();
            let imagePath;

            try {
              imagePath = require(`../logos/${lowerCaseStoreName}/${lowerCaseStoreName}.png`);
            } catch (error) {
              imagePath = require('../logos/stock.png'); // Path to your stock image
            }

            return (
              <img
                key={index}
                src={imagePath}
                alt={store}
              />
            );
          })}
        </div>
        <div className="stores-icons-2">
          {stores.slice(5, 10).map((store, index) => {
            const lowerCaseStoreName = store.toLowerCase();
            let imagePath;

            try {
              imagePath = require(`../logos/${lowerCaseStoreName}/${lowerCaseStoreName}.png`);
            } catch (error) {
              imagePath = require('../logos/stock.png'); // Path to your stock image
            }

            return (
              <img
                key={index}
                src={imagePath}
                alt={store}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
