// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-section {
  background-color: #ffae00;
  text-align: center;
  padding: 50px 20px;
  max-width: 50%;
}

.welcome-message h2 {
  margin: 0;
  font-size: 4rem;
}

.popular-stores {
  margin-top: 40px;
}

.popular-stores h3 {
  font-size: 2rem;
}

.stores-icons-1 img {
  margin: 0 10px;
  width: 100px;
  /* Adjust the size accordingly */
  height: auto;
}

.stores-icons-2 img {
  margin: 0 10px;
  width: 100px;
  /* Adjust the size accordingly */
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/css/HeroSection.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gCAAgC;EAChC,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gCAAgC;EAChC,YAAY;AACd","sourcesContent":[".hero-section {\n  background-color: #ffae00;\n  text-align: center;\n  padding: 50px 20px;\n  max-width: 50%;\n}\n\n.welcome-message h2 {\n  margin: 0;\n  font-size: 4rem;\n}\n\n.popular-stores {\n  margin-top: 40px;\n}\n\n.popular-stores h3 {\n  font-size: 2rem;\n}\n\n.stores-icons-1 img {\n  margin: 0 10px;\n  width: 100px;\n  /* Adjust the size accordingly */\n  height: auto;\n}\n\n.stores-icons-2 img {\n  margin: 0 10px;\n  width: 100px;\n  /* Adjust the size accordingly */\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
