import React, { useEffect, useState } from 'react';
import '../css/Header.css';

const Header = () => {
  const [isDiscoverVisible, setDiscoverVisible] = useState(false);

  const toggleDiscover = () => {
    setDiscoverVisible(!isDiscoverVisible);
  };

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/stores`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setStores(data);
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/categories`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCategories(data);
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <>
      <header className="header">
        <div className="logo">
          <a href="cpcave.com"><img src={require('../logos/cc_logo.png')} alt="Logo" id="logoimg" /></a>
          <span className="no-highlight">Coupon Cave</span>
        </div>
        <nav className="navigation">
          <a href="#discover" id="discover" onClick={toggleDiscover}>
            Discover Deals
          </a>
          <a href="#search" className="search-icon">🔍</a>
        </nav>
      </header>

      {isDiscoverVisible && (
        <section className="discover-section">
          <div className="stores">
            <h3>Find Deal by Store</h3>
            <ul>
              {stores.slice(0, 10).map((store, index) => (
                <li key={index} class="store-list">{store}</li>
              ))}
              <li className="category-list">Show more Stores</li>
            </ul>
          </div>
          <div className="categories">
            <h3>Categories</h3>
            <ul>
              {categories.slice(0, 10).map((category, index) => (
                <li key={index} className="category-list">{category}</li>
              ))}
              <li className="category-list">Show more categories</li>
            </ul>
          </div>
        </section>
      )}
    </>
  );
};

export default Header;
